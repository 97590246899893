import { useCallback, useEffect, useState } from 'react';
import styles from './index.less';
import { Layout, Grid } from 'antd';
import { JoinClassName } from 'src/utils/utils';
import loadScript from 'src/third_party/loadScript';
const { useBreakpoint } = Grid;

const OUT_GROW_BIZ_ID = '672db63284b62f1d3e9d902c';
export function NewProductionRecommendationSurvey() {
  const screens = useBreakpoint();
  const [closedFlag, setClosedFlag] = useState(undefined);
  const [canShown, setCanShown] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setCanShown(true);
      setClosedFlag(undefined);
    }, 10 * 1000);

    return () => {
      timer && clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const closePopuupListenre = () => {
      setCanShown(false);
      setClosedFlag(true);
      document.body.style.removeProperty('overflow');
    };

    const scriptReadyListener = () => {
      if (!canShown) return;
      document.body.style.setProperty('overflow', 'hidden');
      document.getElementById(`${OUT_GROW_BIZ_ID}`)?.querySelector('.outgrow-l')?.click();
      document.getElementById(`close-popup-${OUT_GROW_BIZ_ID}`)?.addEventListener('click', closePopuupListenre, false);
    };
    document.addEventListener(`${OUT_GROW_BIZ_ID}_ready`, scriptReadyListener);

    const autoLoadScript = () => {
      loadScript({
        id: `${OUT_GROW_BIZ_ID}_laod_script`,
        code: `
                    <!-- the new product recommendation survey pop-up Code -->
                    var og_temp=document.createElement('script');
                    og_temp.async=true;
                    og_temp.defer=true;
                    og_temp.src='//dyv6f9ner1ir9.cloudfront.net/assets/js/nploaderV2.js';
                    og_temp.onload=function(){
                        initIframe('${OUT_GROW_BIZ_ID}');
                          document.dispatchEvent(new CustomEvent("${OUT_GROW_BIZ_ID}_ready", {
                            detail: { },
                        }));
                        og_temp.onload = null;
                    };
                    document.querySelector('head').appendChild(og_temp);
                    <!-- the new product recommendation survey pop-up Code -->
           `,
      });
    };

    if (canShown) {
      setClosedFlag(false);
      document.body.style.removeProperty('overflow');
      autoLoadScript();
    }
    return () => {
      document
        .getElementById(`close-popup-${OUT_GROW_BIZ_ID}`)
        ?.removeEventListener('click', closePopuupListenre, false);
      document.removeEventListener(`${OUT_GROW_BIZ_ID}_ready`, scriptReadyListener);
      let loadscript = document.getElementById(`${OUT_GROW_BIZ_ID}_laod_script`);
      loadscript && document.body.removeChild(loadscript);
      document.getElementById(`overlay-popup-${OUT_GROW_BIZ_ID}`)?.remove();
    };
  }, [canShown]);

  if (closedFlag) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={JoinClassName(styles.opInteractiveOuterParent)}>
        <div
          className={JoinClassName(styles.opInteractive, screens.xs ? styles.isMobile : '')}
          id={`${OUT_GROW_BIZ_ID}`}
          data-autoClose="false"
          data-closeTimer="5000"
          data-openOnScroll="false"
          data-scrollValue="500"
          data-title="Product  Recommendation"
          data-embedCookieDays="10"
          data-embedScheduling="false"
          data-embedTimed="true"
          data-embedExit="false"
          data-embedTimeFormat="0"
          data-embedTimeValue="5"
          data-embedBorderRadius="0"
          data-embedFontSize="12"
          data-textcolor="#FB5F66"
          data-bgcolor="#FB5F66"
          data-prop="outgrow-p"
          data-type="outgrow-l"
          data-url={`https://chowbus.outgrow.us/${OUT_GROW_BIZ_ID}?q=1`}
          data-text="Get Started"
          data-width={'100%'}
          style={{ width: '100%' }}
        ></div>
      </div>
    </React.Fragment>
  );
}
