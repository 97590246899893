const loadScript = ({ id, src, code, crossorigin }) => {
  const scriptTag = document.getElementById(id);
  if (!scriptTag) {
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.id = id;
      if (crossorigin) {
        script.crossorigin = crossorigin;
      }
      if (src) {
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
      } else if (code) {
        script.innerHTML = code;
        resolve();
      } else {
        reject('missing script.');
        return;
      }
      document.body.append(script);
    });
  }
};

export default loadScript;
